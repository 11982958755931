import { graphql, useStaticQuery } from 'gatsby';

type ContentType = {
    tableOfContents: {
        items: {
            title: string;
            url: string;
        }[];
    };
    fields: {
        slug: string;
    };
    frontmatter: {
        title: string;
    };
}

export const getAllPosts = () => {
    const data = useStaticQuery(graphql`
    query GetAllPostsHandbookPrimary {
      allMdx(sort: {fields: frontmatter___order}, filter: {fileAbsolutePath: {regex: "/(handbook/primary)/"}}) {
        nodes {
          tableOfContents(maxDepth: 3)
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  `);
    const articles = data.allMdx.nodes;
    return articles;
};
